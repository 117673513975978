import { ENUM_ZooplaPropertyListingStatus } from '@api-new/common';

export const ENUM_PropertyZooplaStatusRecord: Record<
  ENUM_ZooplaPropertyListingStatus,
  {
    label: string;
    colorClass: string;
  }
> = {
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_FOR_SALE]: { label: 'For sale', colorClass: 'status-blue' },
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_SALE_UNDER_OFFER]: {
    label: 'Sale under offer',
    colorClass: 'status-blue',
  },
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_SOLD]: { label: 'Sold', colorClass: 'status-gray' },
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_TO_RENT]: { label: 'To rent', colorClass: 'status-orange' },
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_RENT_UNDER_OFFER]: {
    label: 'Rent under offer',
    colorClass: 'status-orange',
  },
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_RENTED]: { label: 'Rented', colorClass: 'status-orange' },
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_UNSPECIFIED]: { label: '-', colorClass: 'status-gray' },
  [ENUM_ZooplaPropertyListingStatus.UNRECOGNIZED]: { label: '-', colorClass: 'status-gray' },
};
