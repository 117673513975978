import { ENUM_PropertyInvestmentType } from '@api-new/common';

export const ENUM_PropertyInvestmentTypeRecord: Record<
  ENUM_PropertyInvestmentType,
  {
    label: string;
  }
> = {
  [ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_UNSPECIFIED]: { label: 'Unspecified' },
  [ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_RESIDENTIAL]: { label: 'Residential' },
  [ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_BUY_TO_LET]: { label: 'Buy to let' },
  [ENUM_PropertyInvestmentType.UNRECOGNIZED]: { label: 'Unrecognized' },
};
